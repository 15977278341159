import type { FC } from 'react';
import React from 'react';

import { FocusedInlineComment } from '../ssr-mode/FocusedInlineComment';

import { InlineCommentsLoadingSkeleton } from './InlineCommentsLoadingSkeleton';

const checkForSSRFocusedComment = () => {
	if (
		window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__ &&
		window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['focusedComment']
	) {
		return true;
	}

	return false;
};

export const InlineCommentsLoading: FC = () => {
	return checkForSSRFocusedComment() ? <FocusedInlineComment /> : <InlineCommentsLoadingSkeleton />;
};

import { LoadableAfterPaint } from '@confluence/loadable';

import { RendererPageCoverPicturePlaceholder } from './RendererCoverPicture/RendererPageCoverPicturePlaceholder';

export {
	propertyToId,
	getRendererCoverPictureInfo,
	getDynamicCoverImageHeight,
} from './coverPictureHelperFunctions';
export { CoverPictureStateContainer } from './CoverPictureStateContainer';
export {
	CALCULATE_SCALED_COVER_IMAGE_HEIGHT,
	MIN_DYNAMIC_COVER_IMAGE_HEIGHT,
	FIXED_WIDTH_OPTION_FF,
	COVER_IMAGE_FIXED_WIDTH,
	COVER_IMAGE_FIXED_HEIGHT,
} from './pageCoverPictureConstants';
export type { CoverPictureId } from './pageCoverPictureTypes';
export { CoverPictureIdPropertyKey } from './pageCoverPictureTypes';
export { usePageHeightWidthObserver } from './usePageHeightWidthObserver';
export { CoverPictureQuery } from './CoverPictureQuery.graphql';
export type {
	CoverPictureQuery as CoverPictureQueryType,
	CoverPictureQueryVariables,
} from './__types__/CoverPictureQuery';

export const EditorPageCoverPictureEntry = LoadableAfterPaint({
	loader: async () => {
		const { EditorPageCoverPictureEntry } = await import(
			/* webpackChunkName: "loadable-EditorCoverPictureEditorPageCoverPictureEntry" */ './EditorCoverPicture/EditorPageCoverPictureEntry'
		);
		return EditorPageCoverPictureEntry;
	},
});

export const PreviewPageCoverPicture = LoadableAfterPaint({
	loader: async () => {
		const { PreviewPageCoverPicture } = await import(
			/* webpackChunkName: "loadable-EditorCoverPicturePreviewPageCoverPicture" */ './EditorCoverPicture/PreviewPageCoverPicture'
		);
		return PreviewPageCoverPicture;
	},
});

export const RendererPageCoverPicture = LoadableAfterPaint({
	loader: async () => {
		return (
			await import(
				/* webpackChunkName: "loadable-RendererCoverPictureRendererPageCoverPicture" */ './RendererCoverPicture/RendererPageCoverPicture'
			)
		).RendererPageCoverPicture;
	},
	loading: RendererPageCoverPicturePlaceholder,
});

import React from 'react';
import { styled } from '@compiled/react';

import { N20 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { ContentAppearanceType } from '@confluence/content-appearance';
import { useIsFixedWidthImageOption } from '@confluence/custom-sites-extensions';

import type { RendererPageCoverPictureComponentProps } from '../pageCoverPictureTypes';
import {
	CALCULATE_SCALED_COVER_IMAGE_HEIGHT,
	COVER_IMAGE_FIXED_WIDTH,
	COVER_IMAGE_FIXED_HEIGHT,
} from '../pageCoverPictureConstants';

type PlaceholderProps = {
	contentAppearance: string;
	isPagePreview: boolean;
	isCustomSitesPageTitleFFOn?: boolean;
	isSpaceOverview?: boolean;
	hasEmoji?: boolean;
	isFixedWidthImageOption?: boolean;
};

/* eslint-disable @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/use-tokens-space, @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766 */
const Placeholder = styled.div<PlaceholderProps>({
	...({ isCustomSitesPageTitleFFOn, contentAppearance, isFixedWidthImageOption }) =>
		isCustomSitesPageTitleFFOn
			? isFixedWidthImageOption
				? {
						marginTop: '-10px',
						width: `${COVER_IMAGE_FIXED_WIDTH}px`,
						margin: '0 auto',
					}
				: {
						marginTop: '-10px',
						width: 'var(--full-cover-image-width, 100%)',
						marginLeft: 'var(--full-cover-image-margin, 0px)',
					}
			: {
					margin: contentAppearance == ContentAppearanceType.DEFAULT ? '0 auto' : 'unset',
					maxWidth: contentAppearance == ContentAppearanceType.DEFAULT ? '760px' : 'unset',
					borderRadius: '3px',
					width: '100%',
				},
	marginBottom: (props) =>
		props.isPagePreview ||
		(props.isCustomSitesPageTitleFFOn && props.isSpaceOverview && props.hasEmoji)
			? token('space.200', '16px')
			: token('space.400', '32px'),
	backgroundColor: token('color.background.neutral', N20),
	height: ({ isCustomSitesPageTitleFFOn, isFixedWidthImageOption }) =>
		isCustomSitesPageTitleFFOn
			? isFixedWidthImageOption
				? `${COVER_IMAGE_FIXED_HEIGHT}px`
				: CALCULATE_SCALED_COVER_IMAGE_HEIGHT
			: '140px',
});
/* eslint-enable @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/use-tokens-space, @atlaskit/ui-styling-standard/no-styled */

export const RendererPageCoverPicturePlaceholder = ({
	coverPicture,
	isPagePreview,
	contentAppearance,
	isCustomSitesPageTitleFFOn,
	isSpaceOverview,
	hasEmoji,
	coverPictureWidthOverride,
}: RendererPageCoverPictureComponentProps) => {
	const hasCoverPicture = isPagePreview
		? !!coverPicture?.draft?.value?.id
		: !!coverPicture?.published?.value?.id;
	const appearance = isPagePreview ? contentAppearance.draft : contentAppearance.published;

	const isFixedWidthImageOption = useIsFixedWidthImageOption({
		coverPictureWidth: coverPictureWidthOverride,
	});

	return hasCoverPicture ? (
		<div>
			<Placeholder
				contentAppearance={appearance}
				isPagePreview={isPagePreview}
				isCustomSitesPageTitleFFOn={isCustomSitesPageTitleFFOn}
				isSpaceOverview={isSpaceOverview}
				hasEmoji={hasEmoji}
				isFixedWidthImageOption={isFixedWidthImageOption}
			/>
		</div>
	) : null;
};

import React, { createContext, useMemo, useState } from 'react';

type ContentRefetchContextValue = {
	refetchContent: boolean;
	setRefetchContent: (shouldRefetchContent: boolean) => void;
};

export const ContentRefetchContext = createContext<ContentRefetchContextValue>({
	refetchContent: false,
	setRefetchContent: () => {},
});
ContentRefetchContext.displayName = 'ContentRefetchContext';

export const ContentRefetchProvider = ({ children }) => {
	const [refetchContent, setRefetchContent] = useState<boolean>(false);

	const providerValue = useMemo(
		() => ({
			refetchContent,
			setRefetchContent: (shouldRefetchContent: boolean) => setRefetchContent(shouldRefetchContent),
		}),
		[refetchContent, setRefetchContent],
	);

	return (
		<ContentRefetchContext.Provider value={providerValue}>
			{children}
		</ContentRefetchContext.Provider>
	);
};

import type { FC } from 'react';
import React from 'react';

import {
	LoadableLazy,
	LoadablePaint,
	LoadableBackground,
	LoadableAfterPaint,
	LoadingPriority,
} from '@confluence/loadable';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';

import { InlineCommentsLoading } from '../src/view-mode/InlineCommentsLoading';
import type { InlineCommentsProps } from '../src/view-mode/InlineComments';
import { FOCUSED_INLINE_COMMENT_METRIC } from '../src/perf.config';

/* View Mode Exports */
const InlineCommentsLoaderPaint = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-inline-comments" */ '../src/view-mode/InlineComments'
			)
		).InlineComments,
	loading: InlineCommentsLoading as any,
});

const InlineCommentsLoaderLazy = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-inline-comments" */ '../src/view-mode/InlineComments'
			)
		).InlineComments,
	loading: InlineCommentsLoading as any,
});

const InlineCommentsLoaderAfterPaint = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-inline-comments" */ '../src/view-mode/InlineComments'
			)
		).InlineComments,
});

const InlineCommentsLoaderBackground = LoadableBackground({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-inline-comments" */ '../src/view-mode/InlineComments'
			)
		).InlineComments,
});

const loaders = {
	[LoadingPriority.LAZY]: InlineCommentsLoaderLazy,
	[LoadingPriority.PAINT]: InlineCommentsLoaderPaint,
	[LoadingPriority.AFTER_PAINT]: InlineCommentsLoaderAfterPaint,
};

export const InlineCommentsLoader: FC<
	InlineCommentsProps & {
		loadingPriority: LoadingPriority;
		focusedCommentId?: string;
	}
> = ({ loadingPriority, focusedCommentId, ...rest }) => {
	const CommentsLoader = loaders[loadingPriority] || InlineCommentsLoaderBackground;

	return (
		<>
			{focusedCommentId && (
				<PageSegmentLoadStart
					key={`start-${focusedCommentId}`}
					metric={FOCUSED_INLINE_COMMENT_METRIC}
				/>
			)}
			<CommentsLoader {...rest} />
		</>
	);
};

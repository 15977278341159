import { LoadableAfterPaint, LoadableBackground } from '@confluence/loadable';

export const CustomHeaderFooterLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-CustomHeaderFooter" */ './CustomHeaderFooter'))
			.CustomHeaderFooter,
});

export const CustomHeaderFooterBackgroundLoader = LoadableBackground({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-CustomHeaderFooter" */ './CustomHeaderFooter'))
			.CustomHeaderFooter,
});

export { CUSTOM_HEADER_FOOTER_TYPES } from './constants';

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SitePermissionType {
  ANONYMOUS = "ANONYMOUS",
  APP = "APP",
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
  JSD = "JSD",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InlineCommentsQuery
// ====================================================

export interface InlineCommentsQuery_content_nodes_space {
  id: string | null;
}

export interface InlineCommentsQuery_content_nodes_operations {
  operation: string | null;
  targetType: string | null;
}

export interface InlineCommentsQuery_content_nodes {
  id: string | null;
  type: string | null;
  space: InlineCommentsQuery_content_nodes_space | null;
  operations: (InlineCommentsQuery_content_nodes_operations | null)[] | null;
}

export interface InlineCommentsQuery_content {
  nodes: (InlineCommentsQuery_content_nodes | null)[] | null;
}

export interface InlineCommentsQuery_comments_nodes_body {
  value: string;
}

export interface InlineCommentsQuery_comments_nodes_links {
  webui: string | null;
  editui: string | null;
}

export interface InlineCommentsQuery_comments_nodes_location_FooterComment {}

export interface InlineCommentsQuery_comments_nodes_location_InlineComment_inlineResolveProperties {
  resolvedTime: any | null;
  resolvedUser: string | null;
}

export interface InlineCommentsQuery_comments_nodes_location_InlineComment {
  type: string;
  inlineMarkerRef: string | null;
  inlineResolveProperties: InlineCommentsQuery_comments_nodes_location_InlineComment_inlineResolveProperties | null;
}

export type InlineCommentsQuery_comments_nodes_location = InlineCommentsQuery_comments_nodes_location_FooterComment | InlineCommentsQuery_comments_nodes_location_InlineComment;

export interface InlineCommentsQuery_comments_nodes_permissions {
  isEditable: boolean;
  isRemovable: boolean;
  isResolvable: boolean;
}

export interface InlineCommentsQuery_comments_nodes_version {
  when: string | null;
  number: number | null;
}

export interface InlineCommentsQuery_comments_nodes_author_Anonymous_profilePicture {
  path: string;
}

export interface InlineCommentsQuery_comments_nodes_author_Anonymous {
  displayName: string | null;
  profilePicture: InlineCommentsQuery_comments_nodes_author_Anonymous_profilePicture | null;
  permissionType: SitePermissionType | null;
}

export interface InlineCommentsQuery_comments_nodes_author_KnownUser_profilePicture {
  path: string;
}

export interface InlineCommentsQuery_comments_nodes_author_KnownUser {
  displayName: string | null;
  profilePicture: InlineCommentsQuery_comments_nodes_author_KnownUser_profilePicture | null;
  permissionType: SitePermissionType | null;
  accountId: string | null;
}

export interface InlineCommentsQuery_comments_nodes_author_User_profilePicture {
  path: string;
}

export interface InlineCommentsQuery_comments_nodes_author_User {
  displayName: string | null;
  profilePicture: InlineCommentsQuery_comments_nodes_author_User_profilePicture | null;
  permissionType: SitePermissionType | null;
  accountId: string | null;
}

export type InlineCommentsQuery_comments_nodes_author = InlineCommentsQuery_comments_nodes_author_Anonymous | InlineCommentsQuery_comments_nodes_author_KnownUser | InlineCommentsQuery_comments_nodes_author_User;

export interface InlineCommentsQuery_comments_nodes_replies_body {
  value: string;
}

export interface InlineCommentsQuery_comments_nodes_replies_links {
  webui: string | null;
  editui: string | null;
}

export interface InlineCommentsQuery_comments_nodes_replies_location_FooterComment {}

export interface InlineCommentsQuery_comments_nodes_replies_location_InlineComment_inlineResolveProperties {
  resolvedTime: any | null;
  resolvedUser: string | null;
}

export interface InlineCommentsQuery_comments_nodes_replies_location_InlineComment {
  type: string;
  inlineMarkerRef: string | null;
  inlineResolveProperties: InlineCommentsQuery_comments_nodes_replies_location_InlineComment_inlineResolveProperties | null;
}

export type InlineCommentsQuery_comments_nodes_replies_location = InlineCommentsQuery_comments_nodes_replies_location_FooterComment | InlineCommentsQuery_comments_nodes_replies_location_InlineComment;

export interface InlineCommentsQuery_comments_nodes_replies_permissions {
  isEditable: boolean;
  isRemovable: boolean;
  isResolvable: boolean;
}

export interface InlineCommentsQuery_comments_nodes_replies_version {
  when: string | null;
  number: number | null;
}

export interface InlineCommentsQuery_comments_nodes_replies_author_Anonymous_profilePicture {
  path: string;
}

export interface InlineCommentsQuery_comments_nodes_replies_author_Anonymous {
  displayName: string | null;
  profilePicture: InlineCommentsQuery_comments_nodes_replies_author_Anonymous_profilePicture | null;
  permissionType: SitePermissionType | null;
}

export interface InlineCommentsQuery_comments_nodes_replies_author_KnownUser_profilePicture {
  path: string;
}

export interface InlineCommentsQuery_comments_nodes_replies_author_KnownUser {
  displayName: string | null;
  profilePicture: InlineCommentsQuery_comments_nodes_replies_author_KnownUser_profilePicture | null;
  permissionType: SitePermissionType | null;
  accountId: string | null;
}

export interface InlineCommentsQuery_comments_nodes_replies_author_User_profilePicture {
  path: string;
}

export interface InlineCommentsQuery_comments_nodes_replies_author_User {
  displayName: string | null;
  profilePicture: InlineCommentsQuery_comments_nodes_replies_author_User_profilePicture | null;
  permissionType: SitePermissionType | null;
  accountId: string | null;
}

export type InlineCommentsQuery_comments_nodes_replies_author = InlineCommentsQuery_comments_nodes_replies_author_Anonymous | InlineCommentsQuery_comments_nodes_replies_author_KnownUser | InlineCommentsQuery_comments_nodes_replies_author_User;

export interface InlineCommentsQuery_comments_nodes_replies {
  id: string;
  body: InlineCommentsQuery_comments_nodes_replies_body;
  contentStatus: string;
  links: InlineCommentsQuery_comments_nodes_replies_links;
  location: InlineCommentsQuery_comments_nodes_replies_location;
  parentId: string | null;
  permissions: InlineCommentsQuery_comments_nodes_replies_permissions;
  version: InlineCommentsQuery_comments_nodes_replies_version;
  author: InlineCommentsQuery_comments_nodes_replies_author;
}

export interface InlineCommentsQuery_comments_nodes {
  id: string;
  body: InlineCommentsQuery_comments_nodes_body;
  contentStatus: string;
  links: InlineCommentsQuery_comments_nodes_links;
  location: InlineCommentsQuery_comments_nodes_location;
  parentId: string | null;
  permissions: InlineCommentsQuery_comments_nodes_permissions;
  version: InlineCommentsQuery_comments_nodes_version;
  author: InlineCommentsQuery_comments_nodes_author;
  replies: (InlineCommentsQuery_comments_nodes_replies | null)[];
}

export interface InlineCommentsQuery_comments {
  nodes: (InlineCommentsQuery_comments_nodes | null)[] | null;
}

export interface InlineCommentsQuery {
  content: InlineCommentsQuery_content | null;
  comments: InlineCommentsQuery_comments | null;
}

export interface InlineCommentsQueryVariables {
  pageId: string;
}

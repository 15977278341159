import {
	createPageSegmentLoadMetric,
	FOCUSED_INLINE_COMMENT_SEGMENT_KEY,
	RENDERER_INLINE_COMMENT_RENDER_SEGMENT_KEY,
} from '@confluence/browser-metrics';

export const FOCUSED_INLINE_COMMENT_METRIC = createPageSegmentLoadMetric({
	key: FOCUSED_INLINE_COMMENT_SEGMENT_KEY,
});

export const RENDERER_INLINE_COMMENT_RENDER_METRIC = createPageSegmentLoadMetric({
	key: RENDERER_INLINE_COMMENT_RENDER_SEGMENT_KEY,
});

import type { FC } from 'react';
import React from 'react';

import { LoadingSkeletonChildren } from '@confluence/inline-comments-common';
import { LoadingSkeletonContainer } from '@confluence/inline-comments-common/entry-points/styled';

type InlineCommentsLoadingSkeletonProps = {
	offset?: number;
};

export const checkForSSRhighlightClick = () => {
	if (
		window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__ &&
		window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['clickedInlineHighlightElements'] &&
		Array.isArray(window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['clickedInlineHighlightElements'])
	) {
		return Boolean(
			window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['clickedInlineHighlightElements'].length,
		);
	}
	return false;
};

export const InlineCommentsLoadingSkeleton: FC<InlineCommentsLoadingSkeletonProps> = ({
	offset = 0,
}) => {
	/* For SSR check window object or use offset if passed */
	// Previously, clicking on a comment via the inline comment button would still generate an offset.
	// That meant that on transitions, the loading skeleton would still flicker, so we check for window.__SSR_RENDERED__ as well
	/// to prevent the flicker on transitions.
	const sideBarOffset =
		(window.__SSR_RENDERED__ &&
			window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__ &&
			window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['sidebarCommentOffset']) ||
		offset;

	return (
		<LoadingSkeletonContainer
			id="inline-comment-sidebar-skeleton"
			data-testid="inline-comment-sidebar-skeleton"
			showSkeleton={checkForSSRhighlightClick() || Boolean(offset)}
			offset={sideBarOffset}
			mode="view"
		>
			<LoadingSkeletonChildren />
		</LoadingSkeletonContainer>
	);
};

import React, { createContext, useContext, useMemo, useState } from 'react';
import type { FC } from 'react';

import type { RendererActions } from '@atlaskit/renderer/actions';

import { useMultivariantFeatureFlag } from '@confluence/session-data';

export type RendererActionsContextType = {
	rendererActions: RendererActions | null;
};

export type RendererActionsDispatchContextType = {
	setRendererActions: (rendererActions?: RendererActions) => void;
};

export const RendererActionsContext = createContext<RendererActionsContextType>({
	rendererActions: null,
});
RendererActionsContext.displayName = 'RendererActionsContext';

export const RendererActionsDispatchContext = createContext<RendererActionsDispatchContextType>({
	setRendererActions: () => {},
});
RendererActionsDispatchContext.displayName = 'RendererActionsDispatchContext';

type RendererActionsProviderProps = {
	children: JSX.Element;
};

export const RendererActionsProviderIfEnabled: FC<RendererActionsProviderProps> = ({
	children,
}) => {
	const [rendererActions, setRendererActions] = useState<any>(null);

	const { cohort } = useMultivariantFeatureFlag(
		// Prevent circular dependency
		'confluence.frontend.renderer.annotation.provider.inline.comments',
		['annotation-provider', 'not-enrolled', 'query-selectors'],
		'not-enrolled',
		true,
	);
	const isRendererAnnotationProviderEnabled = cohort === 'annotation-provider';

	const providerValue = useMemo(
		() => ({
			rendererActions,
		}),
		[rendererActions],
	);

	const dispatchProviderValue = useMemo(
		() => ({
			setRendererActions,
		}),
		[setRendererActions],
	);

	return isRendererAnnotationProviderEnabled ? (
		<RendererActionsContext.Provider value={providerValue}>
			<RendererActionsDispatchContext.Provider value={dispatchProviderValue}>
				{children}
			</RendererActionsDispatchContext.Provider>
		</RendererActionsContext.Provider>
	) : (
		children
	);
};

export const useRendererActions = () => {
	return useContext(RendererActionsContext);
};

export const useRendererActionsDispatch = () => {
	return useContext(RendererActionsDispatchContext);
};

import { LoadableAfterPaint } from '@confluence/loadable';

export const ReadingAidsAcronymsHighlighter = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-readingAidsAcronymsHighlighter" */ '../src/ReadingAidsAcronymsHighlighter'
			)
		).ReadingAidsAcronymsHighlighter,
});

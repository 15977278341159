import { LoadablePaint } from '@confluence/loadable';

export const InlineCommentsHighlighterLoader = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-LazyInlineCommentsHighlighter" */ './InlineCommentsHighlighter'
			)
		).InlineCommentsHighlighter,
});
